<template>
  <div>
    <v-form>
      <ValidationObserver ref="basicInfoValidationObserver">
        <v-row class="ma-4">
          <v-col cols="6">
            <ValidationProvider
              ref="vendorTypeProvider"
              v-slot="{ errors }"
              name="Vendor Type"
              rules="required"
            >
              <v-select
                :id="'VendorType'"
                v-model="vendorType"
                outlined
                :items="vendorTypeList"
                item-text="Vendor_Type"
                item-value="Vendor_Type_Id"
                label="Vendor Type*"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Vendor Type
                  <span style="color: red">*</span>
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider
              ref="vendorNameProvider"
              v-slot="{ errors }"
              name="Vendor Company Name"
              rules="required|alphaSpaceDot|min:3|max:50"
            >
              <v-text-field
                :id="'VendorName'"
                v-model="vendorName"
                outlined
                label="Vendor Company Name*"
                :counter="50"
                :maxlength="50"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Vendor Company Name
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col lg="6" sm="12">
            <ValidationProvider
              ref="businessNumberProvider"
              v-slot="{ errors }"
              :name="
                isAustralian
                  ? 'Business Number'
                  : 'Corporate Identification Number'
              "
              :rules="
                isAustralian
                  ? 'required|numSpaceHyphen|min:11|max:30'
                  : 'required|alphaNumHySpace|min:11|max:30'
              "
            >
              <v-text-field
                :id="'BusinessNumber'"
                v-model="businessNumber"
                outlined
                :label="
                  isAustralian
                    ? 'Australian Business Number*'
                    : 'Corporate Identification Number*'
                "
                :counter="30"
                :maxlength="30"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{
                    isAustralian
                      ? "Australian Business Number"
                      : "Corporate Identification Number"
                  }}
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider
              ref="serviceOffered"
              v-slot="{ errors }"
              name="Service Offered"
              rules="required"
            >
              <v-autocomplete
                :id="'ServiceOffered'"
                v-model="serviceOffered"
                outlined
                :items="serviceOfferedList"
                label="Service Offered*"
                :error-messages="errors"
                :search-input.sync="serviceOfferedSearch"
              >
                <template v-slot:label>
                  Service Offered
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider
              ref="typeOfIndustry"
              v-slot="{ errors }"
              name="Industry Type"
              rules="required"
            >
              <v-autocomplete
                :id="'typeOfIndustry'"
                v-model="typeOfIndustry"
                outlined
                :items="typeOfIndustryList"
                label="Industry Type*"
                :error-messages="errors"
                :search-input.sync="typeOfIndustrySearch"
              >
                <template v-slot:label>
                  Industry Type
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col v-if="isAustralian" cols="12">
            <v-radio-group v-model="isEntityTrust" row>
              <span class="mr-5"> Is the Entity a Trust? </span>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            v-if="isEntityTrust && isAustralian"
            cols="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
          >
            <ValidationProvider
              ref="trusteeNameProvider"
              v-slot="{ errors }"
              name="Trustee Name"
              rules="alphaSpaceDot|min:3|max:50"
            >
              <v-text-field
                :id="'TrusteeName'"
                v-model="trusteeName"
                outlined
                :counter="50"
                :maxlength="50"
                label="Trustee Name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="isEntityTrust && isAustralian"
            cols="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
          >
            <DragAndDropFiles
              :id="'trusteeDocuments'"
              file-category="trusteeAuthorizationDocuments"
              :old-file-name="fileName"
              :key-index="'trusteeDocuments-File-Upload'"
              @file-event-success="updateFileName($event)"
              @file-event-triggered="$emit('file-event-triggered')"
            ></DragAndDropFiles>
          </v-col>
          <v-col v-if="isAustralian" cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-radio-group v-model="isGSTRegistered" row>
              <span class="mr-5"> Is VAT/GST/Sale Tax Registered? </span>
              <v-radio label="Yes" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="isGSTRegistered" cols="6" lg="6" sm="12">
            <ValidationProvider
              ref="gstNumber"
              v-slot="{ errors }"
              name="VAT/GST/Sales Tax ID"
              rules="required|alphaNumHySpace|min:1|max:30"
            >
              <v-text-field
                :id="'VAT/GST/Sales Tax ID'"
                v-model="gstNumber"
                outlined
                :counter="50"
                :maxlength="50"
                label="VAT/GST/Sales Tax ID*"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <div class="body-1 primary--text font-weight-medium my-2">
              Business Address<span style="color: red">*</span>
            </div>
            <div
              v-if="!isManualForBusinessAddress"
              class="d-flex py-3"
              style="width: 100%"
            >
              <vue-google-autocomplete
                id="map"
                classname="form-control pa-3"
                style="width: 100%; border: 1px solid #e0e0e0"
                placeholder="Please type your address"
                :fields="['address_components', 'geometry']"
                v-on:placechanged="setBusinessAddress"
                :enable-geolocation="true"
              >
              </vue-google-autocomplete>
            </div>
            <v-col cols="12" sm="12" lg="3" md="4" xl="3" class="pa-0">
              <div>
                <v-checkbox
                  v-model="isManualForBusinessAddress"
                  label="No autocomplete address found"
                ></v-checkbox>
              </div>
            </v-col>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="streetProvider"
              v-slot="{ errors }"
              name="Street"
              rules="required|max:300"
            >
              <v-text-field
                :id="'street'"
                v-model="street"
                outlined
                label="Street*"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
                :disabled="!isManualForBusinessAddress ? true : false"
              >
                <template v-slot:label>
                  Street
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cityProvider"
              v-slot="{ errors }"
              name="City"
              rules="required|max:50"
            >
              <v-text-field
                :id="'city'"
                v-model="city"
                outlined
                label="City*"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
                :disabled="!isManualForBusinessAddress ? true : false"
              >
                <template v-slot:label>
                  City
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="stateProvider"
              v-slot="{ errors }"
              name="State"
              rules="required|max:50"
            >
              <v-text-field
                :id="'stateRegion'"
                v-model="stateRegion"
                outlined
                label="State/Region*"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
                :disabled="!isManualForBusinessAddress ? true : false"
              >
                <template v-slot:label>
                  State/Region
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="countryProvider"
              v-slot="{ errors }"
              name="Country"
              rules="required"
            >
              <v-autocomplete
                :id="'country'"
                v-model="country"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                label="Country*"
                outlined
                autocomplete
                :error-messages="errors"
                :search-input.sync="countrySearch"
                @change="onChangePermanentAddress()"
                :disabled="!isManualForBusinessAddress ? true : false"
              >
                <template v-slot:label>
                  Country
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="zipCodeProvider"
              v-slot="{ errors }"
              name="Zip Code"
              rules="required|alphaNumHySpace|max:10"
            >
              <v-text-field
                :id="'zipCode'"
                v-model="zipCode"
                outlined
                label="ZipCode*"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
                :disabled="!isManualForBusinessAddress ? true : false"
              >
                <template v-slot:label>
                  Zipcode
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <div class="body-1 primary--text font-weight-medium my-2">
              Postal Address<span style="color: red">*</span>
            </div>
            <v-col cols="12" sm="12" lg="3" md="4" xl="3" class="pa-0">
              <v-switch
                v-model="sameAsPermanent"
                inset
                :true-value="1"
                :false-value="0"
                color="primary"
                label="Same as above address?"
                @change="onChangeAddressFlag()"
              ></v-switch>
            </v-col>
            <div v-if="!sameAsPermanent && !isManualForPostalAddress">
              <vue-google-autocomplete
                id="map1"
                classname="form-control pa-3 my-4"
                style="width: 100%; border: 1px solid #e0e0e0"
                placeholder="Please type your address"
                :fields="['address_components', 'geometry']"
                v-on:placechanged="setPostalAddress"
                :enable-geolocation="true"
              >
              </vue-google-autocomplete>
            </div>
            <v-col
              v-if="!sameAsPermanent"
              cols="12"
              sm="12"
              lg="3"
              md="4"
              xl="3"
              class="pa-0"
            >
              <v-checkbox
                v-model="isManualForPostalAddress"
                label="No autocomplete address found"
              ></v-checkbox>
            </v-col>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="postalStreetProvider"
              v-slot="{ errors }"
              name="Postal Street"
              rules="required|max:300"
            >
              <v-text-field
                :id="'postalStreet'"
                v-model="postalStreet"
                outlined
                label="Postal Street*"
                @change="onChangeAddressFlag()"
                :error-messages="errors"
                :disabled="
                  sameAsPermanent || !isManualForPostalAddress ? true : false
                "
              >
                <template v-slot:label>
                  Postal Street
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="postalCityProvider"
              v-slot="{ errors }"
              name="Postal City"
              rules="required|max:50"
            >
              <v-text-field
                :id="'postalCity'"
                v-model="postalCity"
                outlined
                label="Postal City*"
                @change="onChangeAddressFlag()"
                :error-messages="errors"
                :disabled="
                  sameAsPermanent || !isManualForPostalAddress ? true : false
                "
              >
                <template v-slot:label>
                  Postal City
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="postalStateProvider"
              v-slot="{ errors }"
              name="Postal State"
              rules="required|max:50"
            >
              <v-text-field
                :id="'postalState'"
                v-model="postalStateRegion"
                outlined
                label="Postal State/Region*"
                @change="onChangeAddressFlag()"
                :error-messages="errors"
                :disabled="
                  sameAsPermanent || !isManualForPostalAddress ? true : false
                "
              >
                <template v-slot:label>
                  Postal State/Region
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="postalCountryProvider"
              v-slot="{ errors }"
              name="Postal Country"
              rules="required"
            >
              <v-autocomplete
                :id="'postalCountry'"
                v-model="postalCountry"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                label="Postal Country*"
                outlined
                autocomplete
                :error-messages="errors"
                :search-input.sync="pCountrySearch"
                @change="onChangeAddressFlag()"
                :disabled="
                  sameAsPermanent || !isManualForPostalAddress ? true : false
                "
              >
                <template v-slot:label>
                  Postal Country
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="postalZipCodeProvider"
              v-slot="{ errors }"
              name="Current Zip Code"
              rules="required|alphaNumHySpace|max:10"
            >
              <v-text-field
                :id="'postalZipCode'"
                v-model="postalZipCode"
                outlined
                label="Postal ZipCode*"
                :error-messages="errors"
                @change="onChangeAddressFlag()"
                :disabled="
                  sameAsPermanent || !isManualForPostalAddress ? true : false
                "
              >
                <template v-slot:label>
                  Postal Zipcode
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <div class="body-1 primary--text font-weight-medium my-2">
              Contact Details
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="contactNameProvider"
              v-slot="{ errors }"
              name="Contact Name of Vendor"
              rules="required|alphaSpaceDot|max:50"
            >
              <v-text-field
                :id="'ContactName'"
                v-model="contactName"
                outlined
                label="Contact Name of Vendor*"
                :counter="50"
                :maxlength="50"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Contact Name of Vendor
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <VueTelInput
              class="pa-3"
              v-model="mobileNo"
              :preferred-countries="['IN', 'US', 'AU']"
              :error="!!mobileNumberValidation"
              error-color="#E53935"
              valid-color="#9E9E9E"
              :inputOptions="mobileNumberTranslation"
              :default-country="getCountryCode(mobileNoCountryCode)"
              :valid-characters-only="true"
              @input="updateMobileNumber"
            ></VueTelInput>
            <span
              :class="
                mobileNumberValidation
                  ? 'red--text caption mt-1'
                  : 'green--text caption mt-1'
              "
              >{{ mobileNumberValidation }}</span
            >
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="telephoneNoProvider"
              v-slot="{ errors }"
              name="Telephone Number"
              rules="mobileNo|max:30"
            >
              <v-text-field
                :id="'telephoneNo'"
                v-model="telephoneNo"
                outlined
                :counter="30"
                :maxlength="30"
                label="Telephone Number"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="emailProvider"
              v-slot="{ errors }"
              name="Vendor Email"
              rules="required|email"
            >
              <v-text-field
                :id="'Vendor Email'"
                v-model="vendorEmail"
                label="Vendor Email*"
                outlined
                :counter="50"
                :maxlength="50"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Vendor Email
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
  </div>
</template>

<script>
import { LIST_COUNTRY } from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  name: "BasicInformation",

  components: { DragAndDropFiles, VueGoogleAutocomplete, VueTelInput },

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // fields
    vendorType: "",
    vendorName: "",
    businessNumber: "",
    trusteeName: "",
    gstNumber: "",
    serviceOffered: "",
    typeOfIndustry: "",
    street: "",
    city: "",
    stateRegion: "",
    zipCode: "",
    country: "",
    postalStreet: "",
    postalCity: "",
    postalStateRegion: "",
    postalZipCode: "",
    postalCountry: "",
    mobileNo: "",
    mobileNoCountryCode: "",
    telephoneNo: "",
    contactName: "",
    vendorEmail: "",
    isManualForBusinessAddress: false,
    isManualForPostalAddress: false,
    fileName: "",
    trusteeAuthorizationDocuments: "",
    entityTrust: "",
    gstRegistered: "",
    // list
    vendorTypeList: [
      "Consultant",
      "Service Providers",
      "Suppliers - Manufacturer",
      "Suppliers - Labour",
      "Suppliers - Plant",
      "Suppliers - Plant & Labour",
    ],
    countryList: [],
    serviceOfferedList: [
      "Consulting Services",
      "Software Development",
      "Hardware Sales and Maintenance",
      "Cloud Services",
      "Managed IT Services",
      "Digital Marketing",
      "Website Development and Design",
      "Graphic Design Services",
      "Financial Services",
      "Human Resources Consulting",
      "Supply Chain Management",
      "Event Planning and Management",
      "Translation and Localization Services",
      "Legal Services",
      "Healthcare Services",
      "Education Services",
      "Retail Services",
      "Construction and Real Estate Services",
      "Hospitality and Tourism Services",
      "Food and Beverage Services",
      "Transportation and Logistics Services",
      "Energy and Utilities Services",
      "Telecommunications Services",
      "Agriculture Services",
      "Media and Entertainment Services",
      "Government and Public Sector Services",
      "Plumbing Services",
      "Electrical Services",
      "Cleaning Services",
      "HVAC (Heating, Ventilation, and Air Conditioning) Services",
      "Pest Control Services",
      "Landscaping and Lawn Care Services",
      "Painting Services",
      "Carpentry Services",
      "Roofing Services",
      "Janitorial Services",
      "Window Cleaning Services",
      "Carpet Cleaning Services",
      "Pressure Washing Services",
      "Appliance Repair Services",
      "Locksmith Services",
      "Other Services",
    ],
    typeOfIndustryList: [
      "Information Technology (IT)",
      "Healthcare",
      "Manufacturing",
      "Retail",
      "Finance and Banking",
      "Construction and Real Estate",
      "Hospitality and Tourism",
      "Food and Beverage",
      "Education",
      "Transportation and Logistics",
      "Energy and Utilities",
      "Telecommunications",
      "Agriculture",
      "Media and Entertainment",
      "Government and Public Sector",
      "Other Industry",
    ],
    // others
    sameAsPermanent: 0,
    isEntityTrust: 0,
    isGSTRegistered: 0,
    mobileNumberTranslation: {
      placeholder: "Mobile Number*",
    },
    mobileNumberProps: {},
    isAustralian: 0,
    serviceOfferedSearch: "",
    typeOfIndustrySearch: "",
    pCountrySearch: "",
    countrySearch: "",
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },

    mobileNumberValidation() {
      if (!this.mobileNo || !this.mobileNoCountryCode) {
        return "Mobile number is required / Please provide a valid mobile number";
      } else if (this.mobileNo) {
        return this.mobileNumberProps.valid ||
          this.mobileNumberProps.valid === undefined
          ? ""
          : "Please provide a valid mobile number";
      }
      return "";
    },
  },

  watch: {
    sameAsPermanent(val) {
      if (val == false) {
        this.postalStreet = "";
        this.postalCity = "";
        this.postalStateRegion = "";
        this.postalZipCode = "";
        this.postalCountry = "";
      }
    },
    postalCountry() {
      this.pCountrySearch = "";
    },
    country() {
      this.countrySearch = "";
    },
    serviceOffered() {
      this.serviceOfferedSearch = "";
    },
    typeOfIndustry() {
      this.typeOfIndustrySearch = "";
    },
  },

  apollo: {
    listCountry: {
      query: LIST_COUNTRY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCountry) {
          this.countryList = data.listCountry.country;
        }
      },
    },
  },

  mounted() {
    if (this.personalInfo && Object.keys(this.personalInfo).length > 0) {
      this.vendorType = this.personalInfo.vendorType;
      this.vendorName = this.personalInfo.vendorName;
      this.businessNumber = this.personalInfo.businessNumber;
      this.trusteeName = this.personalInfo.trusteeName;
      this.gstNumber = this.personalInfo.gstNumber;
      this.serviceOffered =
        this.personalInfo.serviceOffered &&
        this.personalInfo.serviceOffered.length
          ? this.personalInfo.serviceOffered
          : "Other Services";
      this.typeOfIndustry =
        this.personalInfo.typeOfIndustry &&
        this.personalInfo.typeOfIndustry.length
          ? this.personalInfo.typeOfIndustry
          : "Other Industry";
      this.street = this.personalInfo.street;
      this.city = this.personalInfo.city;
      this.stateRegion = this.personalInfo.stateRegion;
      this.zipCode = this.personalInfo.zipCode;
      this.country = this.personalInfo.country;
      this.postalStreet = this.personalInfo.postalStreet;
      this.postalCity = this.personalInfo.postalCity;
      this.postalStateRegion = this.personalInfo.postalStateRegion;
      this.postalZipCode = this.personalInfo.postalZipCode;
      this.postalCountry = this.personalInfo.postalCountry;
      this.mobileNo = this.personalInfo.mobileNo
        ? this.personalInfo.mobileNo
        : "";
      this.mobileNoCountryCode = this.personalInfo.mobileNoCountryCode
        ? this.personalInfo.mobileNoCountryCode
        : "";
      this.telephoneNo = this.personalInfo.telephone;
      this.contactName = this.personalInfo.contactNameOfVendor;
      this.vendorEmail = this.personalInfo.vendorEmail;
      this.trusteeAuthorizationDocuments =
        this.personalInfo.trusteeAuthorizationDocuments;
      this.fileName = this.personalInfo.trusteeAuthorizationDocuments
        ? this.personalInfo.trusteeAuthorizationDocuments.split("?")[3]
        : "";
      this.entityTrust = this.personalInfo.entityTrust;
      this.isEntityTrust = this.personalInfo.entityTrust == "Yes" ? 1 : 0;
      this.gstRegistered = this.personalInfo.gstRegistered;
      this.isGSTRegistered = this.personalInfo.gstRegistered == "Yes" ? 1 : 0;
    }
    let country = localStorage.getItem("country");
    if (country && country.toLowerCase() === "au") {
      this.isAustralian = 1;
    }
  },

  methods: {
    updateFileName(fileName) {
      this.trusteeAuthorizationDocuments = fileName;
      this.fileName = fileName ? fileName.split("?")[3] : "";
    },

    getCountryCode(mobileNoCountryCode) {
      if (mobileNoCountryCode) {
        let countryCode = parseInt(mobileNoCountryCode);
        return countryCode;
      } else {
        return "";
      }
    },

    updateMobileNumber(payload, payload2) {
      this.mobileNumberProps = payload2;
      if (payload2 && Object.keys(payload2).length > 0) {
        this.mobileNo = payload2.nationalNumber;
        this.mobileNoCountryCode = "+" + payload2.countryCallingCode;
      }
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },

    resetBusinessAddress() {
      this.street = "";
      this.city = "";
      this.stateRegion = "";
      this.country = "";
      this.zipCode = "";
    },

    resetPostalAddress() {
      this.postalStreet = "";
      this.postalCity = "";
      this.postalStateRegion = "";
      this.postalCountry = "";
      this.postalZipCode = "";
    },

    setBusinessAddress(addressData, fullData) {
      this.resetBusinessAddress();
      for (const component of fullData.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "subpremise": {
            this.street = component.long_name + "/";
            break;
          }

          case "street_number": {
            this.street = this.street + component.long_name;
            break;
          }

          case "street_address":
          case "premise":
          case "establishment":
          case "route": {
            this.street && this.street.length
              ? (this.street = this.street + ", " + component.long_name)
              : (this.street = component.long_name);
            break;
          }

          case "neighborhood":
          case "sublocality_level_2":
          case "sublocality_level_1":
          case "sublocality_level_3":
          case "landmark": {
            this.street && this.street.length
              ? (this.street = this.street + ", " + component.long_name)
              : (this.street = component.long_name);
            break;
          }

          case "locality": {
            this.city && this.city.length
              ? ""
              : (this.city = component.long_name);
            break;
          }

          case "administrative_area_level_2":
          case "administrative_area_level_3": {
            this.city && this.city.length
              ? this.street && this.street.length
                ? (this.street = this.street + ", " + component.long_name)
                : (this.street = component.long_name)
              : (this.city = component.long_name);
            break;
          }

          case "administrative_area_level_1": {
            this.city && !this.city.length
              ? (this.city = component.long_name)
              : null;
            this.stateRegion = component.long_name;
            break;
          }

          case "country": {
            this.country = component.short_name;
            break;
          }

          case "postal_code": {
            this.zipCode = component.long_name;
            break;
          }

          case "postal_code_prefix":
          case "postal_code_suffix": {
            this.zipCode =
              this.zipCode && this.zipCode.length
                ? (this.zipCode = `${this.zipCode}-${component.long_name}`)
                : (this.zipCode = component.long_name);
            break;
          }
        }
      }
      this.onChangeAddressFlag();
    },

    setPostalAddress(addressData, fullData) {
      this.resetPostalAddress();
      for (const component of fullData.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "subpremise": {
            this.postalStreet = component.long_name + "/";
            break;
          }

          case "street_number": {
            this.postalStreet = this.postalStreet + component.long_name;
            break;
          }

          case "street_address":
          case "premise":
          case "establishment":
          case "route": {
            this.postalStreet && this.postalStreet.length
              ? (this.postalStreet =
                  this.postalStreet + ", " + component.long_name)
              : (this.postalStreet = component.long_name);
            break;
          }

          case "neighborhood":
          case "sublocality_level_2":
          case "sublocality_level_1":
          case "sublocality_level_3":
          case "administrative_area_level_2":
          case "landmark": {
            this.postalStreet && this.postalStreet.length
              ? (this.postalStreet =
                  this.postalStreet + ", " + component.long_name)
              : (this.postalStreet = component.long_name);
            break;
          }

          case "locality": {
            this.postalCity && this.postalCity.length
              ? ""
              : (this.postalCity = component.long_name);
            break;
          }

          case "administrative_area_level_3": {
            this.postalCity && this.postalCity.length
              ? this.postalStreet && this.postalStreet.length
                ? (this.postalStreet =
                    this.postalStreet + ", " + component.long_name)
                : (this.postalStreet = component.long_name)
              : (this.postalCity = component.long_name);
            break;
          }

          case "administrative_area_level_1": {
            this.postalCity && !this.postalCity.length
              ? (this.postalCity = component.long_name)
              : null;
            this.postalStateRegion = component.long_name;
            break;
          }

          case "country": {
            this.postalCountry = component.short_name;
            break;
          }

          case "postal_code": {
            this.postalZipCode = component.long_name;
            break;
          }

          case "postal_code_prefix":
          case "postal_code_suffix": {
            this.postalZipCode =
              this.postalZipCode && this.postalZipCode.length
                ? (this.postalZipCode = `${this.postalZipCode}-${component.long_name}`)
                : (this.postalZipCode = component.long_name);
            break;
          }
        }
      }
      this.onChangeAddressFlag();
    },

    onChangePermanentAddress() {
      this.onChangeAddressFlag();
    },
    onChangeAddressFlag() {
      if (this.sameAsPermanent) {
        this.postalStreet = this.street;
        this.postalCity = this.city;
        this.postalStateRegion = this.stateRegion;
        this.postalCountry = this.country;
        this.postalZipCode = this.zipCode;
      }
    },
    basicInfoFields() {
      return {
        vendorType: this.vendorType,
        vendorName: this.vendorName,
        businessNumber: this.businessNumber,
        trusteeName: this.trusteeName,
        gstNumber: this.gstNumber,
        serviceOffered: this.serviceOffered,
        typeOfIndustry: this.typeOfIndustry,
        street: this.street,
        city: this.city,
        stateRegion: this.stateRegion,
        zipCode: this.zipCode,
        country: this.country,
        postalStreet: this.postalStreet,
        postalCity: this.postalCity,
        postalStateRegion: this.postalStateRegion,
        postalZipCode: this.postalZipCode,
        postalCountry: this.postalCountry,
        mobileNo: this.mobileNo,
        mobileNoCountryCode: this.mobileNoCountryCode,
        telephone: this.telephoneNo,
        contactNameOfVendor: this.contactName,
        vendorEmail: this.vendorEmail,
        trusteeAuthorizationDocuments: this.trusteeAuthorizationDocuments,
        entityTrust: this.isEntityTrust ? "Yes" : "No",
        gstRegistered: this.isGSTRegistered ? "Yes" : "No",
      };
    },

    validateBasicInfo() {
      return new Promise((resolve) => {
        this.$refs.basicInfoValidationObserver
          .validate()
          .then((validationResponse) => {
            resolve(validationResponse);
          });
      });
    },
  },
};
</script>
