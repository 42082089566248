<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                Documents
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn color="primary" text @click="addMoreDocuments()">
                <v-icon>add</v-icon>
                Add Documents
              </v-btn>
            </div>
            <v-form
              v-for="(document, index) in addDocuments"
              :key="index"
              :ref="'documentForm' + index"
            >
              <div
                v-if="addDocuments.length > 1 || document.Document_Id"
                class="d-flex justify-end mb-3"
              >
                <v-btn
                  color="primary lighten-1"
                  text
                  @click="deleteVendorDocument(index, document)"
                >
                  <v-icon>delete</v-icon>
                  Remove
                </v-btn>
              </div>
              <ValidationObserver :ref="'documentsForm' + index">
                <v-row>
                  <v-col cols="12">
                    <v-card flat class="pa-6">
                      <v-row>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentNameProvider"
                            v-slot="{ errors }"
                            name="Document Name"
                            rules="required|alphaNumSpaceWithTwoSymbol"
                          >
                            <v-text-field
                              :id="'documentName'"
                              v-model="document.Document_Name"
                              outlined
                              :counter="50"
                              :maxlength="50"
                              label="Document Name"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentCategory"
                            v-slot="{ errors }"
                            name="Document Category"
                            rules="required"
                          >
                            <v-autocomplete
                              :id="'DocumentCategory'"
                              v-model="document.Document_Category"
                              outlined
                              :items="documentCategoryList"
                              :error-messages="errors"
                              label="Document Category"
                              item-text="Category_Name"
                              item-value="Category_Id"
                              @change="
                                getDocumentTypes(
                                  document.Document_Category,
                                  index
                                )
                              "
                            >
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentType"
                            v-slot="{ errors }"
                            name="Document Type"
                            rules="required"
                          >
                            <v-autocomplete
                              :id="'DocumentType'"
                              v-model="document.Document_Type"
                              outlined
                              :items="documentTypeList[index]"
                              :error-messages="errors"
                              :disabled="!document.Document_Category"
                              label="Document Type"
                              item-text="Document_Type"
                              item-value="Document_Type_Id"
                              @change="
                                getSubDocumentTypeList(
                                  document.Document_Type,
                                  index
                                )
                              "
                            >
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="subDocumentType"
                            v-slot="{ errors }"
                            name="Document Sub Type"
                            rules="required"
                          >
                            <v-autocomplete
                              :id="'SubDocumentType'"
                              v-model="document.Document_Sub_Type"
                              outlined
                              :items="subDocumentTypeList[index]"
                              :error-messages="errors"
                              :disabled="!document.Document_Type"
                              label="Document Sub Type"
                              item-text="Document_Sub_Type"
                              item-value="Document_Sub_Type_Id"
                            >
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <v-dialog v-model="effectiveDateModal" width="290">
                            <template #activator="{ on }">
                              <ValidationProvider
                                ref="effectiveDateProvider"
                                v-slot="{ errors }"
                                name="Effective Date"
                                rules="required"
                              >
                                <v-text-field
                                  :id="'DocumentEffectiveDate'"
                                  :value="formatDate(document.Effective_Date)"
                                  outlined
                                  readonly
                                  label="Effective Date"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="document.Effective_Date"
                              :max="document.End_Date"
                              @change="effectiveDateModal = false"
                            >
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <v-dialog v-model="expiryDateModal" width="290">
                            <template #activator="{ on }">
                              <ValidationProvider
                                ref="expiryDateProvider"
                                v-slot="{ errors }"
                                name="Expiry Date"
                                rules="required"
                              >
                                <v-text-field
                                  :id="'DocumentExpiryDate'"
                                  :value="formatDate(document.End_Date)"
                                  readonly
                                  outlined
                                  :disabled="!document.Effective_Date"
                                  label="Expiry Date"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="document.End_Date"
                              :min="document.Effective_Date"
                              @change="expiryDateModal = false"
                            >
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <DragAndDropFiles
                            :id="'document' + index"
                            file-category="vendorDocuments"
                            :key-index="'document-file-upload-' + index"
                            :old-file-name="document.File_Name"
                            :is-error="document.fileValidationError"
                            :Mandatory="true"
                            :message="true"
                            @file-event-success="updateFileName($event, index)"
                            @file-event-triggered="isFileLoading = $event"
                          ></DragAndDropFiles>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </ValidationObserver>
              <v-divider></v-divider>
            </v-form>
            <v-bottom-sheet
              v-model="openBottomSheet"
              hide-overlay
              persistent
              no-click-animation
              width="100%"
              :retain-focus="false"
            >
              <v-sheet>
                <div class="pt-3 pa-1 d-flex justify-end">
                  <v-btn
                    color="primary"
                    style="border-radius: 10px"
                    large
                    :loading="saveLoading"
                    :disabled="isFileLoading"
                    @click="onSaveDocuments()"
                    >Save & Continue</v-btn
                  >
                  <v-btn
                    class="ml-2"
                    color="primary"
                    style="border-radius: 10px"
                    large
                    text
                    elevation="4"
                    @click="onSkipDocuments()"
                    >Skip</v-btn
                  >
                </div>
              </v-sheet>
            </v-bottom-sheet>
            <v-overlay
              absolute
              :value="documentLoading || saveLoading"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  LIST_DOC_CATEGORY,
  LIST_DOC_TYPE,
  LIST_SUB_DOC_TYPE,
} from "@/graphql/OnboardingQueries";
import {
  GET_VENDOR_DOCUMENTS,
  ADD_UPDATE_VENDOR_DOCUMENTS,
  DELETE_VENDOR_DOCUMENTS,
} from "@/graphql/vendorOnboardQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";

export default {
  name: "VendorDocuments",

  components: { DragAndDropFiles },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  data: () => ({
    addDocuments: [],
    documentCategoryList: [],
    documentTypeList: [],
    subDocumentTypeList: [],
    saveLoading: false,
    openBottomSheet: true,
    apiCallCount: 0,
    documentLoading: true,
    isFileLoading: false,
    vendorId: 0,
    effectiveDateModal: false,
    expiryDateModal: false,
  }),

  apollo: {
    listDocumentCategory: {
      query: LIST_DOC_CATEGORY,
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
          Vendor_Based: 1,
        };
      },
      loading: true,
      result({ data }) {
        if (data && data.listDocumentCategory) {
          this.documentCategoryList = data.listDocumentCategory.category;
        }
      },
    },
    listVendorDocuments: {
      query: GET_VENDOR_DOCUMENTS,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          vendorId: this.vendorId,
        };
      },
      result({ data }) {
        let vm = this;
        if (data && data.listVendorDocuments) {
          const { vendorDocuments } = data.listVendorDocuments;
          if (vendorDocuments && vendorDocuments.length > 0) {
            let documentList = [];
            this.documentTypeList = [];
            this.subDocumentTypeList = [];
            for (let [index, document] of vendorDocuments.entries()) {
              vm.getDocumentTypes(document.Category_Id, index);
              vm.getSubDocumentTypeList(document.Document_Type_Id, index);
              let documentObj = {
                Document_Id: document.Document_Id,
                Document_Name: document.Document_Name,
                Document_Category: document.Category_Id,
                Document_Type: document.Document_Type_Id,
                Document_Sub_Type: document.Document_Sub_Type_Id,
                Effective_Date: document.Effective_Date,
                End_Date: document.End_Date,
                File_Name: document.File_Name
                  ? document.File_Name.split("?")[3]
                  : "",
                fileNameWithoutFormat: document.File_Name,
                fileValidationError: "",
              };
              documentList.push(documentObj);
            }
            vm.addDocuments = documentList;
          } else {
            vm.addDocuments.push({
              Document_Name: "",
              Document_Category: null,
              Document_Type: null,
              Document_Sub_Type: null,
              Effective_Date: null,
              End_Date: null,
              File_Name: "",
              fileNameWithoutFormat: "",
              fileValidationError: "",
            });
          }
        }
        vm.documentLoading = false;
      },
      // Error handling
      error() {
        this.documentLoading = false;
      },
    },
  },

  mounted() {
    let vendorId = parseInt(localStorage.getItem("vendorId"));
    this.vendorId = vendorId;
  },

  methods: {
    getDocumentTypes(categoryId, index) {
      let vm = this;
      vm.documentTypeList[index] = [];
      vm.subDocumentTypeList[index] = [];
      vm.$apollo
        .query({
          query: LIST_DOC_TYPE,
          fetchPolicy: "no-cache",
          variables: {
            Category_Id: categoryId
              ? categoryId
              : vm.documentCategoryList[index],
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Vendor_Based: 1,
          },
        })
        .then((res) => {
          const documentType = res?.data?.listDocumentType?.documentType ?? [];
          vm.documentTypeList.splice(index, 1, documentType);
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    getSubDocumentTypeList(documentType, index) {
      this.subDocumentTypeList[index] = [];
      let vm = this;
      vm.$apollo
        .query({
          query: LIST_SUB_DOC_TYPE,
          fetchPolicy: "no-cache",
          variables: {
            type_id: documentType
              ? documentType
              : vm.subDocumentTypeList[index],
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Vendor_Based: 1,
          },
        })
        .then((res) => {
          const subDocumentType =
            res?.data?.listDocumentSubType?.documentSubType ?? [];
          vm.subDocumentTypeList.splice(index, 1, subDocumentType);
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    updateFileName(fileName, index) {
      this.addDocuments[index]["fileNameWithoutFormat"] = fileName;
      this.addDocuments[index]["fileValidationError"] = "";
    },

    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },

    addMoreDocuments() {
      this.validateDocuments().then((isValid) => {
        if (isValid) {
          this.addDocuments.push({
            Document_Name: "",
            Document_Category: null,
            Document_Type: null,
            Document_Sub_Type: null,
            File_Name: "",
            Effective_Date: null,
            End_Date: null,
            fileNameWithoutFormat: "",
            fileValidationError: "",
          });
        }
      });
    },
    validateDocuments() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let aIndex in this.addDocuments) {
          this.$refs["documentsForm" + aIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              let aIndexFileName =
                this.addDocuments[aIndex]["fileNameWithoutFormat"];
              if (validationResponse && aIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addDocuments.length) {
                  resolve(isValidCount === this.addDocuments.length);
                }
              } else if (!aIndexFileName) {
                this.addDocuments[aIndex]["fileValidationError"] =
                  "Document is required";
                resolve(false);
              }
            });
        }
      });
    },

    onSaveDocuments() {
      this.apiCallCount = 0;
      for (let document of this.addDocuments) {
        this.validateDocuments().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            this.addUpdateVendorDocument(document);
          }
        });
      }
    },

    onSkipDocuments() {
      this.$emit("vendor-documents-saved");
    },

    addUpdateVendorDocument(documentItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_UPDATE_VENDOR_DOCUMENTS,
          variables: {
            Document_Id: documentItem.Document_Id
              ? documentItem.Document_Id
              : 0,
            Vendor_Id: this.vendorId,
            File_Name: documentItem.fileNameWithoutFormat,
            Document_Name: documentItem.Document_Name,
            Category_Id: documentItem.Document_Category,
            Document_Type_Id: documentItem.Document_Type,
            Document_Sub_Type_Id: documentItem.Document_Sub_Type,
            Effective_Date: documentItem.Effective_Date,
            End_Date: documentItem.End_Date,
            checkAccess: 0,
          },
          client: "apolloClientC",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.$emit("vendor-documents-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.saveLoading = false;
          }
        });
    },

    deleteVendorDocument(index, documentItem) {
      let vm = this;
      vm.saveLoading = true;
      if (documentItem.Document_Id) {
        vm.$apollo
          .mutate({
            mutation: DELETE_VENDOR_DOCUMENTS,
            variables: {
              documentId: documentItem.Document_Id,
              checkAccess: 0,
            },
            client: "apolloClientC",
          })
          .then(async () => {
            await this.$store
              .dispatch("removeFile", {
                fileName: documentItem.File_Name,
                category: "vendorDocuments",
              })
              .then(() => {
                vm.saveLoading = false;
                let snackbarData = {
                  isOpen: true,
                  message: "Vendor document deleted successfully",
                  type: "success",
                };
                vm.showAlert(snackbarData);
                vm.addDocuments.splice(index, 1);
                if (index === 0 && vm.addDocuments.length === 0) {
                  vm.addDocuments.push({
                    Document_Name: "",
                    Document_Category: null,
                    Document_Type: null,
                    Document_Sub_Type: null,
                    Effective_Date: null,
                    End_Date: null,
                    File_Name: "",
                    fileNameWithoutFormat: "",
                    fileValidationError: "",
                  });
                }
              })
              .catch((deleteDocErr) => {
                throw deleteDocErr;
              });
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
        vm.saveLoading = false;
      } else {
        vm.addDocuments.splice(index, 1);
        vm.saveLoading = false;
      }
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
